import React from "react"
import letraI from "../images/i.png";
import letraN from "../images/n.png";
import letraT from "../images/t.png";
import letraE from "../images/e.png";
import letraX from "../images/x.png";

export default function Loading(){

    return(
    <div className="loader">
        <div className="intex">
            <div className="letra"><img className="responsiva" src={letraI} alt="Logo Intex letra I"/></div>
            <div className="letra"><img className="responsiva" src={letraN} alt="Logo Intex letra N"/></div>
            <div className="letra"><img className="responsiva" src={letraT} alt="Logo Intex letra T"/></div>
            <div className="letra"><img className="responsiva" src={letraE} alt="Logo Intex letra E"/></div>
            <div className="letra"><img className="responsiva" src={letraX} alt="Logo Intex letra X"/></div>
        </div>
        <div className="lds-ripple"><div></div><div></div></div>
    </div>
    );
}